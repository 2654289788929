<template>
  <!-- <dv-charts :option="option" style="width:100%;height:100%;"></dv-charts> -->
  <dv-active-ring-chart :config="config" style="width:100%;height:100%;"></dv-active-ring-chart>
</template>

<script>
export default {
  data() {
    return {
      config: {
        showOriginValue: true,
        lineWidth: 50,
        data: [
          {
            name: "平整度",
            value: 55,
          },
          {
            name: "静力触探",
            value: 120,
          },
          {
            name: "针入度",
            value: 78,
          },
          {
            name: "软化点",
            value: 66,
          },
          {
            name: "稳定度",
            value: 80,
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {
    let that = this;
    that.axios.post("BigScreen/GetRecordCount").then(function (response) {
      console.log(response);
    });
  },
};
</script>

<style>
</style>