<template>
  <div>
    <el-col :span="8">
      <dv-decoration-8 style="height:100px;"></dv-decoration-8>
    </el-col>
    <el-col :span="8">
      <dv-decoration-5 style="width:100%;height:100px;">123</dv-decoration-5>
    </el-col>
    <el-col :span="8">
      <dv-decoration-8 :reverse="true" style="width:100%;height:100px;"></dv-decoration-8>
    </el-col>
  </div>
</template>

<script>
// import Vue from "vue";
// import dataV from "@jiaminghi/data-view";

// Vue.use(dataV);
export default {};
</script>

<style>
</style>