<template>
  <dv-full-screen-container class="main">
    <bheader class="bheader"></bheader>
    <bmain class="bmain"></bmain>
    <bfooter class="bfooter"></bfooter>
  </dv-full-screen-container>
</template>

<script>
import Vue from "vue";
import dataV from "@jiaminghi/data-view";
import bheader from "./BHeader";
import bfooter from "./BFooter";
import bmain from "./BMain";

Vue.use(dataV);
export default {
    components: { bheader, bfooter, bmain },
  data() {
    return {};
  },
};
</script>

<style scoped>
.main {
  background-image: url("/images/bigscreen/bg.png");
  display: flex;
  flex-direction: column;
}
.bheader {
  height: 100px;
  width: 100%;
}
.bmain {
  flex: 1;
}
.bfooter {
  height: 100px;
}
</style>