<template>
  <el-container>
    <el-header height="100px" class="header">
      <div>
        <div class="item-top">平整度</div>
        <dv-digital-flop :config="config_PZD1" class="item-body"></dv-digital-flop>
      </div>
      <div>
        <div class="item-top">针入度</div>
        <dv-digital-flop :config="config_PZD2" class="item-body"></dv-digital-flop>
      </div>
      <div>
        <div class="item-top">软件点</div>
        <dv-digital-flop :config="config_PZD3" class="item-body"></dv-digital-flop>
      </div>
      <div>
        <div class="item-top">延伸仪</div>
        <dv-digital-flop :config="config_PZD4" class="item-body"></dv-digital-flop>
      </div>

    </el-header>
    <dv-decoration-2 style="height:5px;" />
    <el-main class="main">
      <dv-border-box-11 title="试验记录" style="width:500px">
        <b-pie></b-pie>
      </dv-border-box-11>
      <div style="flex:1;display:flex;flex-direction: column;">
        <dv-border-box-1 style="height:50%;">
          <div style="height:100%;display:flex;flex-direction: column;">
            <div style="color:white;text-align:center;font-size:30px;">在线设备总数</div>
            <div style="display:flex;justify-content:space-between;">
              <dv-decoration-1 style="width:200px;height:50px;margin-left:50px;" />
              <dv-decoration-1 style="width:200px;height:50px;margin-right:50px;" />
            </div>
            <div style="flex:1;display:flex;justify-content:center;align-items:center">
              <div class="card">0</div>
              <div class="card">0</div>
              <div class="card">2</div>
              <div class="card">1</div>
            </div>
          </div>
        </dv-border-box-1>
        <div style="height:25%;display:flex;">
          <dv-border-box-5 style="flex:1">
            <div style="margin:50px;">
              <span style="font-size:50px;color:#00c0ff;">1120</span><span style="color:white;font-size:20px;margin-left:30px;font-weight:700;">条</span>
            </div>
          </dv-border-box-5>
          <div style="flex:1;">
            <div style="font-size:20px;color:white;margin-left:40px;margin-top:20px;">试验合格数</div>
            <dv-decoration-3 style="width:250px;height:30px;margin-left:40px;" />
          </div>
        </div>
        <div style="height:25%;display:flex;">
          <div style="flex:1;display:flex;flex-direction: column;justify-content:flex-end;align-items:flex-end">
            <div style="font-size:20px;color:white;margin-right:40px;">试验总记录数</div>
            <dv-decoration-3 style="width:250px;height:30px;margin-right:40px;margin-bottom:20px;" />
          </div>
          <dv-border-box-5 style="flex:1;" :reverse="true">
            <div style="height:80%;margin-right:50px;display:flex;align-items:flex-end;justify-content:flex-end;">
              <span style="font-size:50px;color:#00c0ff;">1120</span><span style="color:white;font-size:20px;margin-left:30px;font-weight:700;">条</span>
            </div>
          </dv-border-box-5>
        </div>
      </div>
      <dv-border-box-11 title="试验记录" style="width:500px">
        <b-pie></b-pie>
      </dv-border-box-11>

    </el-main>
  </el-container>
</template>

<script>
import BPie from "./BPie.vue";
export default {
  components: { BPie },
  data() {
    return {
      dur: 3,
      config_PZD1: {
        number: [100],
        content: "{nt}条",
        style: {
          fill: "#4d99fc",
          fontWeight: "bold",
        },
      },
      config_PZD2: {
        number: [152],
        content: "{nt}条",
        style: {
          fill: "#4d99fc",
          fontWeight: "bold",
        },
      },
      config_PZD3: {
        number: [536],
        content: "{nt}条",
        style: {
          fill: "#4d99fc",
          fontWeight: "bold",
        },
      },
      config_PZD4: {
        number: [632],
        content: "{nt}条",
        style: {
          fill: "#4d99fc",
          fontWeight: "bold",
        },
      },
    };
  },
  methods: {
    handleClick() {
      let that = this;
      that.dur = 0;
    },
  },
};
</script>

<style scoped>
.device_panel {
  display: flex;
  justify-content: space-around;
}
.device {
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  height: 100px;
  text-align: center;
  color: #79b0fb;
}
.header {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);
}
.header > div {
  flex: 1;
  height: 80px;
  border-left: 3px solid rgb(6, 30, 93);
  border-right: 3px solid rgb(6, 30, 93);
  display: flex;
  flex-direction: column;
}
.item-top {
  height: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}
.item-body {
  height: 50px;
}
.main {
  display: flex;
}
.card {
  background-color: rgba(4, 49, 128, 0.6);
  color: #08e5ff;
  height: 70px;
  width: 70px;
  font-size: 45px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  margin: 10px;
}
</style>